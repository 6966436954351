import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  AccessManagementRuleDto,
  BusinessGoalDto,
  CreateAnonymizationRuleRequest,
  CreateProjectRequest,
  DashboardDto,
  DashboardViewDto,
  DashboardViewTemplateDto,
  DdosOverviewDto,
  DeploymentInfo,
  DeploymentSummaryDto,
  EndpointDto,
  FirewallActionCountDto,
  FirewallBotRuleDetailsDto,
  FirewallEvents,
  FirewallPolicyDto,
  FirewallPolicyExclusionDto,
  FirewallPolicyPotentialExclusionDetailDto,
  FirewallPolicyPotentialExclusionDto,
  FirewallPotentialExclusionLockDto,
  FirewallRuleGroupDto,
  FirewallTrackingBaseInfoDto,
  FirewallTrackingIpHistoryInfoDto,
  GrantProjectAccessRequest,
  GroupedFirewallRuleSamplesDto,
  IgnoredPotentialExclusionDto,
  Int64DatedValue,
  ProjectDto,
  RecommendationDto,
  Result,
  RevokeProjectAccessRequest,
  SetProjectFirewallSettingsRequest,
  UpdateAnonymizationRuleRequest,
  UpdateProjectRequest,
  UserProfileDto,
  WhitelistDto,
} from "./contracts/models";

export class ProjectApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Project
   * @name ListAllProjects
   * @request GET:/api/projects
   * @response `200` `(ProjectDto)[]` OK
   */
  listAllProjects = (params: RequestParams = {}) =>
    this.http
      .request<ProjectDto[], any>({
        path: `/api/projects`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name Create
   * @request POST:/api/projects
   * @response `200` `void` OK
   */
  create = (data: CreateProjectRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name Update
   * @request PUT:/api/projects/{projectId}
   * @response `200` `Result` OK
   */
  update = (projectId: string, data: UpdateProjectRequest, params: RequestParams = {}) =>
    this.http
      .request<Result, any>({
        path: `/api/projects/${projectId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name SetFirewallSettings
   * @request POST:/api/projects/{projectId}/firewall-settings
   * @response `200` `Result` OK
   */
  setFirewallSettings = (projectId: string, data: SetProjectFirewallSettingsRequest, params: RequestParams = {}) =>
    this.http
      .request<Result, any>({
        path: `/api/projects/${projectId}/firewall-settings`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetPageSpeedPaths
   * @request POST:/api/projects/{projectId}/endpoint/{endpoint}/page-speed-paths
   * @response `200` `(string)[]` OK
   */
  getPageSpeedPaths = (projectId: string, endpoint: string, params: RequestParams = {}) =>
    this.http
      .request<string[], any>({
        path: `/api/projects/${projectId}/endpoint/${endpoint}/page-speed-paths`,
        method: "POST",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListDeploymentsWithDetails
   * @request GET:/api/projects/{projectId}/deployments-detailed
   * @response `200` `(DeploymentSummaryDto)[]` OK
   */
  listDeploymentsWithDetails = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<DeploymentSummaryDto[], any>({
        path: `/api/projects/${projectId}/deployments-detailed`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicies
   * @request GET:/api/projects/{projectId}/firewall-policies/list
   * @response `200` `(FirewallPolicyDto)[]` OK
   */
  listFirewallPolicies = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<FirewallPolicyDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/list`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name PrefetchDeploymentInfos
   * @request GET:/api/projects/{projectId}/prefetch-deployment-infos
   * @response `200` `Record<string,(DeploymentInfo)[]>` OK
   */
  prefetchDeploymentInfos = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<Record<string, DeploymentInfo[]>, any>({
        path: `/api/projects/${projectId}/prefetch-deployment-infos`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallRuleGroups
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}
   * @response `200` `(FirewallRuleGroupDto)[]` OK
   */
  listFirewallRuleGroups = (
    projectId: string,
    policyId: string,
    query?: {
      timeSpan?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<FirewallRuleGroupDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetFirewallPolicyDetails
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/details
   * @response `200` `Record<string,(FirewallEvents)[]>` OK
   */
  getFirewallPolicyDetails = (
    projectId: string,
    policyId: string,
    query?: {
      timeSpan?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<Record<string, FirewallEvents[]>, any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/details`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicyExclusions
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/exclusions
   * @response `200` `(FirewallPolicyExclusionDto)[]` OK
   */
  listFirewallPolicyExclusions = (projectId: string, policyId: string, params: RequestParams = {}) =>
    this.http
      .request<FirewallPolicyExclusionDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/exclusions`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicyPotentialExclusions
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/potential-exclusions
   * @response `200` `(FirewallPolicyPotentialExclusionDto)[]` OK
   */
  listFirewallPolicyPotentialExclusions = (
    projectId: string,
    policyId: string,
    query?: {
      /** @default "14d" */
      timeSpan?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<FirewallPolicyPotentialExclusionDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/potential-exclusions`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicyPotentialExclusionLocks
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/potential-exclusion-locks
   * @response `200` `(FirewallPotentialExclusionLockDto)[]` OK
   */
  listFirewallPolicyPotentialExclusionLocks = (projectId: string, policyId: string, params: RequestParams = {}) =>
    this.http
      .request<FirewallPotentialExclusionLockDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/potential-exclusion-locks`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicyIgnoredPotentialExclusions
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/ignored-potential-exclusions
   * @response `200` `(IgnoredPotentialExclusionDto)[]` OK
   */
  listFirewallPolicyIgnoredPotentialExclusions = (projectId: string, policyId: string, params: RequestParams = {}) =>
    this.http
      .request<IgnoredPotentialExclusionDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/ignored-potential-exclusions`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicyPotentialExclusionDetails
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/potential-exclusion-details
   * @response `200` `(FirewallPolicyPotentialExclusionDetailDto)[]` OK
   */
  listFirewallPolicyPotentialExclusionDetails = (
    projectId: string,
    policyId: string,
    query?: {
      /** @default "14d" */
      timeSpan?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<FirewallPolicyPotentialExclusionDetailDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/potential-exclusion-details`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListFirewallPolicyBotDetails
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/bot-details
   * @response `200` `(FirewallBotRuleDetailsDto)[]` OK
   */
  listFirewallPolicyBotDetails = (
    projectId: string,
    policyId: string,
    query?: {
      /** @default "14d" */
      timeSpan?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<FirewallBotRuleDetailsDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/bot-details`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetFirewallRuleSamplesQuery
   * @request GET:/api/projects/{projectId}/firewall-policies/{policyId}/samples/{ruleId}
   * @response `200` `(GroupedFirewallRuleSamplesDto)[]` OK
   */
  getFirewallRuleSamplesQuery = (
    projectId: string,
    policyId: string,
    ruleId: string,
    query?: {
      /** @default "14d" */
      timeSpan?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<GroupedFirewallRuleSamplesDto[], any>({
        path: `/api/projects/${projectId}/firewall-policies/${policyId}/samples/${ruleId}`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListBusinessGoals
   * @request GET:/api/projects/{projectId}/business-goals
   * @response `200` `(BusinessGoalDto)[]` OK
   */
  listBusinessGoals = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<BusinessGoalDto[], any>({
        path: `/api/projects/${projectId}/business-goals`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListRecommendations
   * @request GET:/api/projects/{projectId}/recommendations
   * @response `200` `(RecommendationDto)[]` OK
   */
  listRecommendations = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<RecommendationDto[], any>({
        path: `/api/projects/${projectId}/recommendations`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListDashboards
   * @request GET:/api/projects/{projectId}/dashboards
   * @response `200` `(DashboardDto)[]` OK
   */
  listDashboards = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<DashboardDto[], any>({
        path: `/api/projects/${projectId}/dashboards`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListWhitelists
   * @request GET:/api/projects/{projectId}/whitelists
   * @response `200` `(WhitelistDto)[]` OK
   */
  listWhitelists = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<WhitelistDto[], any>({
        path: `/api/projects/${projectId}/whitelists`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListDashboardViews
   * @request GET:/api/projects/{projectId}/dashboard-views
   * @response `200` `(DashboardViewDto)[]` OK
   */
  listDashboardViews = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<DashboardViewDto[], any>({
        path: `/api/projects/${projectId}/dashboard-views`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListEndpoints
   * @request GET:/api/projects/{projectId}/endpoints
   * @response `200` `(EndpointDto)[]` OK
   */
  listEndpoints = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<EndpointDto[], any>({
        path: `/api/projects/${projectId}/endpoints`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name RequestUsageDataUpdate
   * @request POST:/api/projects/{projectId}/request-usage-data-update
   * @response `200` `void` OK
   */
  requestUsageDataUpdate = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/request-usage-data-update`,
        method: "POST",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name RequestEgressDataUpdate
   * @request POST:/api/projects/{projectId}/request-egress-data-update
   * @response `200` `void` OK
   */
  requestEgressDataUpdate = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/request-egress-data-update`,
        method: "POST",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetFirewallActionCount
   * @request GET:/api/projects/{projectId}/firewall-action-count
   * @response `200` `FirewallActionCountDto` OK
   */
  getFirewallActionCount = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<FirewallActionCountDto, any>({
        path: `/api/projects/${projectId}/firewall-action-count`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetFirewallBlockHistory
   * @request GET:/api/projects/{projectId}/firewall-block-history
   * @response `200` `(Int64DatedValue)[]` OK
   */
  getFirewallBlockHistory = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<Int64DatedValue[], any>({
        path: `/api/projects/${projectId}/firewall-block-history`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name RequestProjectOverviewDataUpdate
   * @request POST:/api/projects/{projectId}/request-overview-data-update
   * @response `200` `void` OK
   */
  requestProjectOverviewDataUpdate = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/request-overview-data-update`,
        method: "POST",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name RequestFirewallRuleGroupsUpdate
   * @request POST:/api/projects/{projectId}/firewall-policies/{firewallPolicyId}/request-firewall-rule-groups-update
   * @response `200` `void` OK
   */
  requestFirewallRuleGroupsUpdate = (projectId: string, firewallPolicyId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/firewall-policies/${firewallPolicyId}/request-firewall-rule-groups-update`,
        method: "POST",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name RequestFirewallRulePolicyDataUpdate
   * @request POST:/api/projects/{projectId}/firewall-policies/{firewallPolicyId}/request-firewall-policy-data-update
   * @response `200` `void` OK
   */
  requestFirewallRulePolicyDataUpdate = (projectId: string, firewallPolicyId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/firewall-policies/${firewallPolicyId}/request-firewall-policy-data-update`,
        method: "POST",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name CreateAnonymizationRule
   * @request POST:/api/projects/{projectId}/anonymization-rules
   * @response `200` `void` OK
   */
  createAnonymizationRule = (projectId: string, data: CreateAnonymizationRuleRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/anonymization-rules`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name UpdateAnonymizationRule
   * @request PUT:/api/projects/{projectId}/anonymization-rules/{ruleId}
   * @response `200` `void` OK
   */
  updateAnonymizationRule = (
    projectId: string,
    ruleId: string,
    data: UpdateAnonymizationRuleRequest,
    params: RequestParams = {},
  ) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/anonymization-rules/${ruleId}`,
        method: "PUT",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name DeleteAnonymizationRule
   * @request DELETE:/api/projects/{projectId}/anonymization-rules/{ruleId}
   * @response `200` `void` OK
   */
  deleteAnonymizationRule = (projectId: string, ruleId: string, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/anonymization-rules/${ruleId}`,
        method: "DELETE",
        credentials: "include",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetFirewallTrackingLookupBase
   * @request GET:/api/projects/{projectId}/firewall-lookup-tracking-base
   * @response `200` `FirewallTrackingBaseInfoDto` OK
   */
  getFirewallTrackingLookupBase = (
    projectId: string,
    query?: {
      trackingReference?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<FirewallTrackingBaseInfoDto, any>({
        path: `/api/projects/${projectId}/firewall-lookup-tracking-base`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetFirewallTrackingLookupIpHistory
   * @request GET:/api/projects/{projectId}/firewall-lookup-tracking-iphistory
   * @response `200` `FirewallTrackingIpHistoryInfoDto` OK
   */
  getFirewallTrackingLookupIpHistory = (
    projectId: string,
    query?: {
      ip?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<FirewallTrackingIpHistoryInfoDto, any>({
        path: `/api/projects/${projectId}/firewall-lookup-tracking-iphistory`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListDdosOverviews
   * @request GET:/api/projects/{projectId}/ddos
   * @response `200` `(DdosOverviewDto)[]` OK
   */
  listDdosOverviews = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<DdosOverviewDto[], any>({
        path: `/api/projects/${projectId}/ddos`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetUserProjects
   * @request GET:/api/projects/user-projects
   * @response `200` `(ProjectDto)[]` OK
   */
  getUserProjects = (params: RequestParams = {}) =>
    this.http
      .request<ProjectDto[], any>({
        path: `/api/projects/user-projects`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetAccessManagementRules
   * @request GET:/api/projects/{projectId}/access-management-rules
   * @response `200` `(AccessManagementRuleDto)[]` OK
   */
  getAccessManagementRules = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<AccessManagementRuleDto[], any>({
        path: `/api/projects/${projectId}/access-management-rules`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GetUserProfiles
   * @request GET:/api/projects/{projectId}/user-profiles
   * @response `200` `(UserProfileDto)[]` OK
   */
  getUserProfiles = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<UserProfileDto[], any>({
        path: `/api/projects/${projectId}/user-profiles`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name ListDashboardViewTemplates
   * @request GET:/api/projects/{projectId}/dashboard-view-templates
   * @response `200` `(DashboardViewTemplateDto)[]` OK
   */
  listDashboardViewTemplates = (projectId: string, params: RequestParams = {}) =>
    this.http
      .request<DashboardViewTemplateDto[], any>({
        path: `/api/projects/${projectId}/dashboard-view-templates`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name GrantProjectAccess
   * @request POST:/api/projects/{projectId}/access/grant
   * @response `200` `void` OK
   */
  grantProjectAccess = (projectId: string, data: GrantProjectAccessRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/access/grant`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Project
   * @name RevokeProjectAccess
   * @request POST:/api/projects/{projectId}/access/revoke
   * @response `200` `void` OK
   */
  revokeProjectAccess = (projectId: string, data: RevokeProjectAccessRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/projects/${projectId}/access/revoke`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
